/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.desk {
  max-width: calc(100% - 60px);
}
.moodcontent .desk {
  padding-left: 135px;
}
#home {
  width: 111px;
  height: 68px;
}
.navbar,
.navbar-scroll {
  width: 150px;
}
.cb-toggle-target-active .navbar.cb-elastic-content-small {
  width: 150px;
}
.navbar-scroll {
  padding-bottom: 35px;
}
#expo div.link .prev.cb-slide-previous,
#expo div.link .next.cb-slide-previous {
  right: 60px;
}
#expo div.link .prev.cb-slide-next,
#expo div.link .next.cb-slide-next {
  right: 30px;
}
.cb-layout1 h1,
h2 {
  font-size: 40px;
  font-size: 4rem;
}
.farnorth h2 {
  font-size: 36px;
  font-size: 3.6rem;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 2.45901639%;
  margin-left: 2.45901639%;
}
.area .part {
  margin-right: 2.45901639%;
  margin-left: 2.45901639%;
  width: 95.08196721%;
}
.area .tiny {
  width: 45.08196721%;
}
.area > .slim {
  width: 50%;
}
.area > .slim .foot,
.area > .slim .part {
  margin-right: 4.91803279%;
  margin-left: 4.91803279%;
}
.area > .slim .part {
  width: 90.16393443%;
}
.area > .slim .tiny {
  width: 40.16393443%;
}
.area.south {
  width: 100%;
}
.area.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.south .foot {
  margin-right: 2.45901639%;
  margin-left: 2.45901639%;
}
.area.south .part {
  margin-right: 2.45901639%;
  margin-left: 2.45901639%;
  width: 95.08196721%;
}
.area.south > .slim .part {
  width: 95.08196721%;
}
.area,
.area.south {
  width: 105.17241379%;
  margin-left: -2.5862069%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.area .unit.slim.pure .part.pict.tiny,
.area .unit.slim.seam .part.pict.tiny {
  width: 95.08196721%;
}
#view .unit.flat {
  height: 45.3125vw;
}
.unit.flat {
  margin-top: 30px;
  margin-bottom: 30px;
}
.unit.flat.slim {
  width: 100%;
}
.unit.flat .body:before {
  right: calc(100% - 320px);
}
.unit.flat .part {
  width: 95.08196721%;
  margin-left: 2.45901639%;
  margin-right: 2.45901639%;
}
.unit.flat .part.text {
  margin-top: 20px;
}
.unit.flat .part:not(.pict) {
  width: 275px;
}
.unit.flat .part.pict {
  height: 45.3125vw;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 60px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */